/* line 15, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
.m-map-tools.is-fixed {
	position: fixed;
	z-index: 999;
	top: 0;
}

/* line 22, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
.listings__map-tools {
	padding-right: 40px;
	margin-bottom: 10px;
	position: relative;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .listings__map-tools {
	padding-right: initial;
	padding-left: 40px;
}

/* line 28, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
.listings__map-help {
	top: 50%;
	transform: translate(-0, -50%);
	right: 0;
	position: absolute;
	cursor: help;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .listings__map-help {
	right: auto;
	left: 0;
}

/* line 36, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
.listings__polygon-tools {
	display: inline-block;
	vertical-align: middle;
}

@media only screen and (max-width: 47.999em) {
	/* line 36, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
	.listings__polygon-tools {
		margin-bottom: 10px;
	}
}

@media only screen and (max-width: 47.999em) {
	/* line 45, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
	.listings__polygon-tools .o-button--bordered.is-grouped {
		margin-left: 0;
		border-radius: 0;
		margin-top: -1px;
		width: 100%;
		box-sizing: border-box;
	}
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .listings__polygon-tools .o-button--bordered.is-grouped {
		margin-left: initial;
		margin-right: 0;
	}
}

@media only screen and (max-width: 47.999em) and (min-width: 0\0) {
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .listings__polygon-tools .o-button--bordered.is-grouped {
		margin-left: 0;
	}
}

/* line 55, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
.listings__polygon-tools--no-label .button__icon {
	margin-right: 0;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .listings__polygon-tools--no-label .button__icon {
	margin-right: initial;
	margin-left: 0;
}

@media screen and (min-width: 0\0) {
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .listings__polygon-tools--no-label .button__icon {
		margin-right: 0;
	}
}

@media only screen and (max-width: 47.999em) {
	/* line 63, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
	.listings__polygon-tool {
		margin: 0;
		width: 100%;
	}
}

/* line 69, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
.listings__polygon-tool .o-button {
	background-color: rgb(var(--color-global-positive));
}

/* line 72, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
.listings__polygon-tool .o-button:hover {
	background-color: rgb(var(--color-global-primary));
	color: rgb(var(--color-global-secondary));
}

/* line 78, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
.listings__polygon-tool.disabled {
	display: none;
}

/* line 83, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
.listings__polygon-tool.selected:not(.disabled) .o-button {
	background-color: rgb(var(--color-global-title));
}

/* line 86, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
.listings__polygon-tool.selected:not(.disabled) .o-button:hover {
	background-color: rgb(var(--color-global-title));
}

/* line 92, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
.listings__polygon-tool .o-button--bordered.is-grouped {
	margin-left: 0;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .listings__polygon-tool .o-button--bordered.is-grouped {
	margin-left: initial;
	margin-right: 0;
}

@media screen and (min-width: 0\0) {
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .listings__polygon-tool .o-button--bordered.is-grouped {
		margin-left: 0;
	}
}

/* line 97, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
.polygon_help {
	display: none;
}

@media only screen and (max-width: 47.999em) {
	/* line 101, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
	.listings__photo-container {
		padding: 0;
	}
}

/* line 107, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
.communities__photo-container {
	padding-top: 10px;
}

/* line 111, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
.m-info-bubble {
	background: rgb(var(--color-global-positive));
}

/* line 115, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
.map-info-bubble,
.map-info-bubble--alt {
	position: relative;
	overflow: hidden;
}

/* line 123, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
.map-info-bubble .listing-item__bottom-details:not(.listing-item__bottom-details-vr),
.map-info-bubble--alt .listing-item__bottom-details:not(.listing-item__bottom-details-vr) {
	height: 40px;
}

/* line 127, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
.map-info-bubble .listing-item__bottom-details-vr,
.map-info-bubble--alt .listing-item__bottom-details-vr {
	position: relative;
	padding-bottom: 20px;
	overflow: hidden;
}

/* line 132, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
.map-info-bubble .listing-item__bottom-details-vr .box-expandable__content,
.map-info-bubble--alt .listing-item__bottom-details-vr .box-expandable__content {
	position: relative;
	box-shadow: none;
}

/* line 137, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
.map-info-bubble .listing-item__bottom-details-vr .c-price,
.map-info-bubble--alt .listing-item__bottom-details-vr .c-price {
	display: inline-block;
	padding-bottom: 10px;
}

/* line 143, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
.map-info-bubble .listing-item__price-container,
.map-info-bubble--alt .listing-item__price-container {
	text-align: left;
	white-space: nowrap;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .map-info-bubble .listing-item__price-container, .is-rtl
.map-info-bubble--alt .listing-item__price-container {
	text-align: right;
}

/* line 148, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
.map-info-bubble .price__value,
.map-info-bubble--alt .price__value {
	font-size: 1.25em;
	line-height: 1;
}

/* line 153, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
.map-info-bubble .price__upon-request,
.map-info-bubble--alt .price__upon-request {
	font-size: 1.083em;
	white-space: nowrap;
}

@media only screen and (min-width: 48em) and (max-width: 79.999em) {
	/* line 153, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
	.map-info-bubble .price__upon-request,
	.map-info-bubble--alt .price__upon-request {
		margin-top: 10px;
	}
}

@media only screen and (max-width: 47.999em) {
	/* line 153, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
	.map-info-bubble .price__upon-request,
	.map-info-bubble--alt .price__upon-request {
		margin-top: 5px;
	}
}

/* line 166, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
.map-info-bubble .price__currency,
.map-info-bubble--alt .price__currency {
	font-size: 1.167em;
}

/* line 172, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
.map-info-bubble .listing-item__feat--inline,
.map-info-bubble--alt .listing-item__feat--inline {
	margin-right: 0;
	padding: 0 10px;
	white-space: nowrap;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .map-info-bubble .listing-item__feat--inline, .is-rtl
.map-info-bubble--alt .listing-item__feat--inline {
	margin-right: initial;
	margin-left: 0;
}

@media screen and (min-width: 0\0) {
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .map-info-bubble .listing-item__feat--inline, .is-rtl
	.map-info-bubble--alt .listing-item__feat--inline {
		margin-right: 0;
	}
}

@media only screen and (min-width: 48em) and (max-width: 79.999em) {
	/* line 172, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
	.map-info-bubble .listing-item__feat--inline,
	.map-info-bubble--alt .listing-item__feat--inline {
		line-height: 1.25em;
	}
}

@media only screen and (max-width: 47.999em) {
	/* line 172, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
	.map-info-bubble .listing-item__feat--inline,
	.map-info-bubble--alt .listing-item__feat--inline {
		line-height: 1.375;
	}
}

/* line 185, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
.map-info-bubble .listing-item__feat--inline:last-child,
.map-info-bubble--alt .listing-item__feat--inline:last-child {
	padding-right: 0;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .map-info-bubble .listing-item__feat--inline:last-child, .is-rtl
.map-info-bubble--alt .listing-item__feat--inline:last-child {
	padding-right: initial;
	padding-left: 0;
}

/* line 190, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
.map-info-bubble .listing-item__feat--placeholder,
.map-info-bubble--alt .listing-item__feat--placeholder {
	padding: 0;
}

/* line 195, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
.map-info-bubble .listing-item__mls-details,
.map-info-bubble--alt .listing-item__mls-details {
	border: none;
}

/* line 198, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
.map-info-bubble .listing-item__mls-details-container,
.map-info-bubble--alt .listing-item__mls-details-container {
	padding: 0;
}

/* line 204, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
.map-info-bubble__parent {
	background: rgb(var(--color-global-positive));
	box-shadow: 0px 0px 15px 0px rgba(var(--color-global-negative), 0.15);
	margin: 5px;
}

/* line 213, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
.office__tab.selected {
	background: #406195;
	color: rgb(var(--color-global-positive));
}

/* line 218, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
.info-bubble__listing-wrap {
	position: relative;
}

/* line 221, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
.info-bubble__listing-wrap .flag_wrapper {
	display: none;
}

/* line 225, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
.info-bubble__listing-wrap .info-bubbble__action-save {
	font-family: var(--font-family-headings);
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}

@media only screen and (max-width: 47.999em) {
	/* line 225, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
	.info-bubble__listing-wrap .info-bubbble__action-save {
		font-size: 1.0519em;
		line-height: 1.135;
	}
}

@media only screen and (min-width: 48em) and (max-width: 79.999em) {
	/* line 225, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
	.info-bubble__listing-wrap .info-bubbble__action-save {
		font-size: 1.14838em;
		line-height: 1.5;
	}
}

@media only screen and (min-width: 80em) {
	/* line 225, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
	.info-bubble__listing-wrap .info-bubbble__action-save {
		font-size: 1.34em;
		line-height: 1.5;
	}
}

/* line 232, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
.info-bubble__listing-wrap .info-bubbble__action-save .icon {
	font-size: 0.834em;
	padding-left: 5px;
}

@media only screen and (max-width: 47.999em) {
	/* line 232, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
	.info-bubble__listing-wrap .info-bubbble__action-save .icon {
		font-size: 0.97161em;
	}
}

@media only screen and (min-width: 48em) and (max-width: 79.999em) {
	/* line 232, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
	.info-bubble__listing-wrap .info-bubbble__action-save .icon {
		font-size: 0.834em;
	}
}

@media only screen and (min-width: 80em) {
	/* line 232, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
	.info-bubble__listing-wrap .info-bubbble__action-save .icon {
		font-size: 0.834em;
	}
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .info-bubble__listing-wrap .info-bubbble__action-save .icon {
	padding-left: initial;
	padding-right: 5px;
}

@media only screen and (max-width: 47.999em) {
	/* line 232, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
	.info-bubble__listing-wrap .info-bubbble__action-save .icon {
		font-size: inherit;
	}
}

/* line 242, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
.info-bubble__listing-wrap .listing-item__agent-photo {
	background-color: rgb(var(--color-global-positive));
}

@media only screen and (min-width: 48em) {
	/* line 246, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
	.listings__map-result-container .info-bubble__listing-wrap {
		display: none;
	}
}

@media only screen and (max-width: 47.999em) {
	/* line 253, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
	.map-info-bubble .info-bubble__listing-wrap {
		display: none;
	}
}

/* line 260, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
.info-bubble__mini {
	display: none;
	background: rgb(var(--color-global-positive));
}

@media only screen and (max-width: 47.999em) {
	/* line 264, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
	.map-info-bubble .info-bubble__mini {
		display: block;
	}
}

/* line 270, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
.info-bubble__mini .price__value,
.info-bubble__mini .price__currency {
	font-size: 1.083em;
}

/* line 275, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
.info-bubble__mini .info-bubble__property {
	width: auto;
	border: 0;
}

/* line 279, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
.info-bubble__mini .info-bubble__property:empty {
	display: none;
}

/* line 283, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
.info-bubble__mini .info-bubble__property:not(:last-child):after {
	padding-right: 5px;
	content: ', ';
	display: inline-block;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .info-bubble__mini .info-bubble__property:not(:last-child):after {
	padding-right: initial;
	padding-left: 5px;
}

/* line 290, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
.info-bubble__mini .info-bubble__property-value {
	display: inline-block;
}

/* line 294, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
.info-bubble__mini .listing-item__feat--inline {
	margin-top: 5px;
	margin-bottom: 5px;
}

/* line 302, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
.map-message {
	width: 100%;
	left: 0 !important;
	background: rgba(var(--color-global-negative), 0.5);
	color: rgb(var(--color-global-positive));
	padding: 10px 4px;
	box-sizing: border-box;
	text-align: center;
}

/* line 311, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
.map-message__text {
	color: rgb(var(--color-global-positive));
}

/* line 316, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
.info-bubble__listing-container {
	max-width: 300px;
}

@media only screen and (max-width: 47.999em) {
	/* line 319, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
	.listings__map-result-container .info-bubble__listing-container {
		max-width: 100%;
		width: 100%;
	}
}

/* line 328, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
.info-bubble__listing-container.is-single .info-bubble__listing:not(.is-center) {
	display: none;
}

/* line 333, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
.info-bubble__listing-container .listing-item__agent-email,
.info-bubble__listing-container .o-phone {
	display: inline-block;
}

/* line 337, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
.info-bubble__listing-container .listing-item__agent-email .icon,
.info-bubble__listing-container .o-phone .icon {
	display: none;
}

/* line 342, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
.info-bubble__listing-container .listing-item__brokerage-name {
	display: block;
	width: 100%;
}

/* line 348, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
.info-bubble__listing {
	background: rgb(var(--color-global-positive));
}

/* line 351, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
.info-bubble__listing .grid__item--spaced {
	padding: 10px 10px 0;
}

/* line 354, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
.info-bubble__listing .grid__item--spaced-all {
	padding: 10px;
}

/* line 359, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
.info-bubble__listing .grid__item--last {
	padding-bottom: 0;
}

/* line 363, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
.info-bubble__listing .o-imagenav,
.info-bubble__listing .imagenav__caption {
	visibility: visible;
	opacity: 1;
	display: block;
}

/* line 371, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
.info-bubble__properties {
	display: inline-block;
	vertical-align: middle;
	width: 100%;
	color: rgb(var(--color-global-secondary));
}

/* line 378, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
.info-bubble__property {
	box-sizing: border-box;
	display: inline-block;
	vertical-align: middle;
	text-align: center;
}

/* line 386, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
.info-bubble__property__value::after, .info-bubble__property__description::after {
	content: '\00a0';
}

/* line 391, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
.info-bubble__property__value {
	font-size: 1.167em;
	font-family: var(--font-family-headings);
	display: inline-block;
	font-display: swap;
}

/* line 398, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
.info-bubble__property__description {
	font-size: 0.92em;
	line-height: 1;
	text-transform: uppercase;
	display: block;
}

/* line 405, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
.info-bubble__property .icon {
	margin: 0 0 0 10px;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .info-bubble__property .icon {
	margin: 0 10px 0 0;
}

/* line 411, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
.info-bubble__permalink {
	display: block;
}

/* line 415, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
.info-bubble__listing-title {
	display: flex;
	padding-bottom: 10px;
}

/* line 419, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
.info-bubble__listing-title .listing-item__select + .listing-item__select-name {
	padding-left: 0;
	flex-grow: 1;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .info-bubble__listing-title .listing-item__select + .listing-item__select-name {
	padding-left: initial;
	padding-right: 0;
}

/* line 424, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
.info-bubble__listing-title .listing-item__select {
	left: auto;
	position: relative;
	top: auto;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .info-bubble__listing-title .listing-item__select {
	left: auto;
	right: auto;
}

/* line 429, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
.info-bubble__listing-title .listing-item__select .checkbox__icon {
	vertical-align: baseline;
}

/* line 434, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
.info-bubble__listing-title .permalink {
	font-size: 22.75px;
	color: rgb(var(--color-global-secondary));
}

/* line 438, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
.info-bubble__listing-title .permalink:hover {
	color: rgb(var(--color-global-secondary));
}

/* line 443, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
.info-bubble__listing-title .address-wrap {
	font-size: 14px;
	color: #656565;
}

/* line 448, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
.info-bubble__listing-details-top .info-bubble__listing-title {
	padding: 10px 20px 10px 10px;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .info-bubble__listing-details-top .info-bubble__listing-title {
	padding: 10px 10px 10px 20px;
}

@media only screen and (max-width: 47.999em) {
	/* line 448, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
	.info-bubble__listing-details-top .info-bubble__listing-title {
		padding: 5px 20px 10px 10px;
	}
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .info-bubble__listing-details-top .info-bubble__listing-title {
		padding: 5px 10px 10px 20px;
	}
}

/* line 457, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
.info-bubble__listing-attribution {
	color: rgb(var(--color-global-primary));
	padding: 5px;
}

@media only screen and (max-width: 47.999em) {
	/* line 461, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
	.map-info-bubble .info-bubble__listing-attribution {
		display: none;
	}
}

/* line 468, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
.info-bubble__action-close {
	z-index: 2;
	right: 0;
	display: block;
	position: absolute;
	top: 0;
	font-size: 1.25em;
	line-height: normal;
	padding: 10px;
	background-color: rgb(var(--color-global-positive));
	color: rgb(var(--color-global-secondary));
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .info-bubble__action-close {
	right: auto;
	left: 0;
}

/* line 481, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
.info-bubble__action-close--background {
	background-color: rgb(var(--color-global-primary));
}

@media only screen and (max-width: 47.999em) {
	/* line 468, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
	.info-bubble__action-close {
		display: block;
	}
}

/* line 491, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
.info-bubble__listing-container .info-bubble__action-close:before, .info-bubble__listing-container .info-bubble__action-close:after {
	content: none;
}

@media only screen and (max-width: 47.999em) {
	/* line 497, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
	.info-bubble__wrapper--border {
		border: 1px solid rgb(var(--color-global-border));
	}
}

/* line 503, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
.info-bubble__close {
	z-index: 999;
	right: -5px;
	border-radius: 100%;
	opacity: 1 !important;
	background-color: rgb(var(--color-global-secondary));
	border: 5px solid rgb(var(--color-global-secondary));
	color: rgb(var(--color-global-positive));
	font-size: 9px;
	text-align: center;
	cursor: pointer;
	position: absolute;
	top: -5px;
	padding: 0;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .info-bubble__close {
	right: auto;
	left: -5px;
}

/* line 520, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
.info-bubble__close:hover {
	color: rgb(var(--color-global-positive));
}

/* line 524, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
.info-bubble__close span {
	display: none !important;
}

/* line 529, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
.info-bubble__pagination {
	display: flex;
	padding: 10px 10px 0 10px;
	background: rgb(var(--color-global-positive));
}

@media only screen and (max-width: 47.999em) {
	/* line 534, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
	.map-info-bubble .info-bubble__pagination {
		display: none !important;
	}
}

/* line 540, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
.map-info-bubble--alt .info-bubble__pagination {
	padding-bottom: 10px;
}

@media only screen and (min-width: 48em) {
	/* line 540, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
	.map-info-bubble--alt .info-bubble__pagination {
		display: none !important;
	}
}

/* line 549, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
.info-bubble__pagination-prev {
	margin-right: 5px;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .info-bubble__pagination-prev {
	margin-right: initial;
	margin-left: 5px;
}

@media screen and (min-width: 0\0) {
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .info-bubble__pagination-prev {
		margin-right: 0;
	}
}

/* line 553, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
.info-bubble__pagination-next {
	margin-left: 5px;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .info-bubble__pagination-next {
	margin-left: initial;
	margin-right: 5px;
}

@media screen and (min-width: 0\0) {
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .info-bubble__pagination-next {
		margin-left: 0;
	}
}

/* line 557, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
.info-bubble__pagination-index {
	flex-grow: 1;
}

/* line 561, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
.info-bubble__pagination-prev,
.info-bubble__pagination-next,
.info-bubble__pagination-index {
	padding: 5px;
	line-height: 1em;
	background: rgb(var(--color-global-primary));
}

/* line 569, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
.info-bubble__loading {
	background-color: rgb(var(--color-global-positive));
	padding: 5px;
}

/* line 574, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
.listing-item__bubble-brokerage-logo {
	margin-right: 10px;
	display: inline-block;
	vertical-align: middle;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .listing-item__bubble-brokerage-logo {
	margin-right: initial;
	margin-left: 10px;
}

@media screen and (min-width: 0\0) {
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .listing-item__bubble-brokerage-logo {
		margin-right: 0;
	}
}

/* line 580, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
.maptoolsmodule .map-message {
	display: none !important;
}

/* line 584, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
.info-bubble__footer {
	background: rgb(var(--color-global-positive));
}

/* line 587, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
.info-bubble__footer .results-logo-wrapper {
	font-size: 0.92em;
}

@media only screen and (max-width: 47.999em) {
	/* line 587, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
	.info-bubble__footer .results-logo-wrapper {
		font-size: 1.0718em;
	}
}

@media only screen and (min-width: 48em) and (max-width: 79.999em) {
	/* line 587, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
	.info-bubble__footer .results-logo-wrapper {
		font-size: 0.92em;
	}
}

@media only screen and (min-width: 80em) {
	/* line 587, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
	.info-bubble__footer .results-logo-wrapper {
		font-size: 0.92em;
	}
}

/* line 592, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
.info-bubble-container {
	display: flex;
	width: 375px;
	max-width: 100%;
}

@media screen and (min-width: 0\0) {
	/* line 592, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
	.info-bubble-container {
		height: 20px;
		min-height: 117px;
	}
}

/* line 602, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
.info-bubble-container__image {
	flex: 1 0 40%;
	overflow: hidden;
	position: relative;
}

/* line 607, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
.info-bubble-container__image-content {
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}

/* line 617, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
.info-bubble-container__listing-details {
	font-size: 12px;
	font-size: 1rem;
	flex: 1 0 60%;
	overflow: hidden;
}

@media only screen and (min-width: 30em) and (max-width: 47.999em) {
	/* line 592, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
	.info-bubble-container {
		width: 100%;
	}
	/* line 626, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
	.info-bubble-container__image {
		flex: 1 0 25%;
	}
	/* line 630, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
	.info-bubble-container__listing-details {
		flex: 1 0 75%;
	}
}

/* line 638, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
.info-bubble__listing-details-container {
	display: flex;
	flex-direction: column;
	height: 100%;
}

/* line 644, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
.info-bubble__listing-details-top {
	flex: 1 1 auto;
}

/* line 648, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
.info-bubble__listing-details-bottom {
	flex: 0 0 auto;
}

/* line 651, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
.info-bubble__listing-details-bottom .listing-item__feat--inline {
	margin-bottom: 0;
}

@media only screen and (min-width: 48em) and (max-width: 79.999em) {
	/* line 651, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
	.info-bubble__listing-details-bottom .listing-item__feat--inline {
		line-height: normal;
	}
}

/* line 659, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
.info-bubble__listing-details-bottom .info-bubbble__action-save {
	right: 5px;
	position: absolute;
	bottom: 5px;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .info-bubble__listing-details-bottom .info-bubbble__action-save {
	right: auto;
	left: 5px;
}

/* line 667, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
.info-bubble__listing-detail {
	overflow-y: auto;
}

/* line 670, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
.info-bubble__listing-detail .listing-item__openhouse-title {
	padding: 0;
}

/* line 673, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
.info-bubble__listing-detail .listing-item__openhouse-title::after {
	right: 0;
}

/* line 678, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
.info-bubble__listing-detail .listing-item__table--overflow {
	max-height: unset;
}

/* line 684, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
.map-control {
	font-size: 12px;
	font-size: 1rem;
	text-align: center;
	margin: 5px 10px;
	text-transform: capitalize;
	border-radius: 2px;
}

/* line 691, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
.map-control:not(.has-group) {
	background: rgb(var(--color-global-positive));
}

/* line 695, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
.map-control:not(.has-group), .map-control__group,
.has-group > .map-control__button {
	box-shadow: 0 0 4px rgba(var(--color-global-negative), 0.2);
}

/* line 701, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
.map-control__button {
	cursor: pointer;
	padding: 5px 10px;
	display: block;
	box-sizing: border-box;
}

/* line 707, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
.map-control__button--stacked {
	display: block;
}

@media only screen and (min-width: 48em) {
	/* line 711, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
	.map-control__button--truncate {
		width: 60px;
	}
}

/* line 716, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
.map-control__button--truncate .map-control__label {
	display: inline-block;
	max-width: 100%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	display: block;
}

/* line 723, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
.map-control__label {
	color: rgb(var(--color-global-secondary));
	letter-spacing: normal;
	line-height: 1;
	font-size: 1em;
}

/* line 737, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
.map-control__label--type {
	color: rgb(var(--color-global-secondary));
	text-transform: uppercase;
	font-weight: bold;
	font-size: 0.92em;
	line-height: 1.5;
}

/* line 750, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
.map-control__icon {
	color: rgb(var(--color-global-secondary));
	padding: 0 5px;
	font-size: "";
}

/* line 756, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
.map-control__tools-icon {
	line-height: normal;
}

/* line 760, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
.map-control__text {
	color: rgb(var(--color-global-secondary));
}

/* line 764, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
.map-control__checkbox {
	visibility: hidden;
	position: absolute;
}

/* line 769, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
.map-control__checkbox:checked + .map-control__button,
.map-control__checkbox.selected + .map-control__button,
.map-control--alt .map-control__button:hover {
	background-color: rgba(var(--color-dynamic-primary-color), .7);
	color: rgb(var(--color-global-positive));
}

/* line 775, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
.map-control__checkbox:checked + .map-control__button .map-control__icon,
.map-control__checkbox:checked + .map-control__button .map-control__label,
.map-control__checkbox.selected + .map-control__button .map-control__icon,
.map-control__checkbox.selected + .map-control__button .map-control__label,
.map-control--alt .map-control__button:hover .map-control__icon,
.map-control--alt .map-control__button:hover .map-control__label {
	color: rgb(var(--color-global-positive));
}

/* line 783, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
.map-control--alt .map-control__button {
	padding: 10px 2px;
	background-color: rgb(var(--color-global-positive));
	border-radius: 2px;
}

/* line 790, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
.map-control--alt .map-control__label {
	padding-top: 5px;
}

/* line 794, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
.map-control--alt .map-control__icon {
	font-size: 1.2em;
	line-height: normal;
}

/* line 799, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
.map-control--alt.has-group {
	display: flex;
	background: transparent;
}

/* line 803, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
.map-control--alt.has-group > .map-control__button {
	align-self: flex-start;
}

@media only screen and (max-width: 47.999em) {
	/* line 809, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
	.map-control--alt .map-control__label {
		display: none;
	}
	/* line 813, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
	.map-control--alt .map-control__button {
		padding: 10px 5px;
	}
}

/* line 819, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
.map-control__group {
	margin-left: 10px;
	text-align: left;
	vertical-align: top;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .map-control__group {
	margin-left: initial;
	margin-right: 10px;
}

@media screen and (min-width: 0\0) {
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .map-control__group {
		margin-left: 0;
	}
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .map-control__group {
	text-align: right;
}

/* line 824, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
.map-control__group .map-control__button {
	padding: 4px 10px;
	color: rgb(var(--color-global-secondary));
	border-bottom: 1px solid rgb(var(--color-global-positive));
}

/* line 830, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
.map-control__group .map-control__icon {
	padding: 0 10px 0 0;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .map-control__group .map-control__icon {
	padding: 0 0 0 10px;
}

/* line 838, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
.map-control--zoom .map-control__button, .map-control__group .map-control__button {
	border: 0;
}

/* line 842, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
.map-control--zoom .map-control__button:first-child, .map-control__group .map-control__button:first-child {
	border-bottom: 1px solid rgb(var(--color-global-border));
	border-radius: 2px 2px 0 0;
}

/* line 847, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
.map-control--zoom .map-control__button:last-child, .map-control__group .map-control__button:last-child {
	border-radius: 0 0 2px 2px;
}

/* line 854, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
.map-control--zoom .map-control__icon, .map-control--street-view .map-control__icon {
	padding: 4px 5px;
	margin: 4px 0;
}

/* line 859, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
.map-control--zoom .map-control__button, .map-control--street-view .map-control__button {
	margin: 0;
	padding: 0 4px;
}

/* line 866, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
.map-control--zoom .map-control__icon {
	font-size: 0.92em;
}

/* line 872, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
.map-control--street-view .map-control__icon {
	font-size: 1.25em;
}

/* line 876, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
.map-control--street-view .map-control__icon,
.map-control--street-view .map-control__button {
	margin: 0;
}

/* line 883, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
.map-control--fullscreen .icon-map-glyphs-full-screen {
	display: block;
}

/* line 886, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
.is-fullscreen .map-control--fullscreen .icon-map-glyphs-full-screen {
	display: none;
}

/* line 890, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
.map-control--fullscreen .icon-map-glyphs-full-screen-exit {
	display: none;
}

/* line 893, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
.is-fullscreen .map-control--fullscreen .icon-map-glyphs-full-screen-exit {
	display: block;
}

/* line 900, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
.map-control.map-control--horizontal {
	margin: 10px 5px;
}

/* line 904, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
.map-control.map-control--bottom-rev {
	flex-direction: column-reverse;
}

/* line 907, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
.map-control.map-control--bottom-rev .map-control__group {
	margin-left: 0;
	position: absolute;
	bottom: 100%;
	margin-bottom: 5px;
	white-space: nowrap;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .map-control.map-control--bottom-rev .map-control__group {
	margin-left: initial;
	margin-right: 0;
}

@media screen and (min-width: 0\0) {
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .map-control.map-control--bottom-rev .map-control__group {
		margin-left: 0;
	}
}

/* line 917, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
.map-control.map-control--top-rev {
	flex-direction: row-reverse;
}

/* line 920, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
.map-control.map-control--top-rev .map-control__group {
	margin-left: 0;
	margin-right: 5px;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .map-control.map-control--top-rev .map-control__group {
	margin-left: initial;
	margin-right: 0;
}

@media screen and (min-width: 0\0) {
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .map-control.map-control--top-rev .map-control__group {
		margin-left: 0;
	}
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .map-control.map-control--top-rev .map-control__group {
	margin-right: initial;
	margin-left: 5px;
}

@media screen and (min-width: 0\0) {
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .map-control.map-control--top-rev .map-control__group {
		margin-right: 0;
	}
}

/* line 926, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
.map-control__button.draw-control.selected, .map-control__button.draw-control--delete:hover {
	color: rgb(var(--color-global-positive));
}

/* line 930, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
.map-control__button.draw-control.selected .map-control__icon, .map-control__button.draw-control--delete:hover .map-control__icon {
	color: rgb(var(--color-global-positive));
}

/* line 935, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
.map-control__button.draw-control.selected {
	background-color: rgba(var(--color-dynamic-primary-color), .7);
}

/* line 939, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
.map-control__button.draw-control--delete:hover {
	background-color: #dd4c3b;
	border-color: #dd4c3b;
}

/* line 947, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
.map-control-wrapper {
	position: relative;
	display: flex;
}

/* line 951, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
.map-control-wrapper .map-control {
	position: static !important;
	flex: 1;
	margin: 0 !important;
	border-radius: 0;
	background: none;
	border-right: 1px solid transparent;
}

/* line 959, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
.map-control-wrapper .map-control__button {
	padding: 4px 10px 4px 10px;
	width: 100%;
	border-radius: 0;
	background-color: rgba(var(--color-global-negative), .7);
	white-space: nowrap;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .map-control-wrapper .map-control__button {
	padding: 4px 10px 4px 10px;
}

/* line 967, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
.map-control-wrapper .map-control__label {
	display: inline-block;
	color: rgb(var(--color-global-positive));
	text-transform: uppercase;
	font-size: 0.92em;
	font-weight: 600;
}

@media only screen and (max-width: 47.999em) {
	/* line 967, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
	.map-control-wrapper .map-control__label {
		font-size: 1.0718em;
	}
}

@media only screen and (min-width: 48em) and (max-width: 79.999em) {
	/* line 967, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
	.map-control-wrapper .map-control__label {
		font-size: 0.92em;
	}
}

@media only screen and (min-width: 80em) {
	/* line 967, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
	.map-control-wrapper .map-control__label {
		font-size: 0.92em;
	}
}

/* line 982, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
.map-control-wrapper .map-control__icon {
	color: rgb(var(--color-global-positive));
	display: inline-block;
	vertical-align: top;
	padding-top: 3px;
}

/* line 989, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
.map-control-wrapper .map-control__radius {
	order: 3;
}

/* line 993, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
.map-control-wrapper .map-control__polygon {
	order: 4;
	border-right: 0;
}

/* line 998, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
.map-control-wrapper .map-control__group {
	text-align: center;
	position: absolute;
	top: 80px;
	right: 10px;
}

/* line 1004, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
.map-control-wrapper .map-control__group span {
	display: block;
}

/* line 1009, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
.map-control-wrapper .map-control__group .map-control__button {
	padding: 10px 0 10px 0;
	color: rgb(var(--color-global-positive));
	width: 55px;
	height: 55px;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .map-control-wrapper .map-control__group .map-control__button {
	padding: 10px 0 10px 0;
}

/* line 1015, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
.map-control-wrapper .map-control__group .map-control__button.draw-control--delete:hover {
	background-color: #a73030;
}

/* line 1020, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
.map-control-wrapper .map-control__group .map-control__icon {
	padding: 0;
}

/* line 1026, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
.map-control-wrapper .map-control__checkbox:checked + .map-control__button,
.map-control-wrapper .map-control__checkbox.selected + .map-control__button,
.map-control-wrapper .map-control--alt .map-control__button:hover {
	background-color: rgba(var(--color-dynamic-primary-color), .7);
}

/* line 1035, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
.map-message-wrapper .map-message {
	top: 26px !important;
	background-color: rgba(var(--color-dynamic-primary-color), .7);
	display: flex;
	align-items: center;
	justify-content: center;
	height: 30px;
}

/* line 1043, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
.map-message-wrapper .map-message p {
	line-height: 1;
}

/* line 1051, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
.map-zoom-wrapper .map-control--zoom .map-control__button {
	width: 30px;
	height: 30px;
	padding: 0;
}

/* line 1057, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
.map-zoom-wrapper .map-control--zoom .map-control__icon {
	font-size: 1.5em;
	font-weight: 600;
	margin: 0;
	padding: 5px;
}

/* line 1065, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
.map-zoom-wrapper .map-control--change-map-type {
	background: none;
	box-shadow: none;
}

/* line 1069, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
.map-zoom-wrapper .map-control--change-map-type .map-control__button {
	padding: 0;
}

/* line 1073, scss/80-themes/AffinityDefault/60-components/listing-item-map.scss */
.map-zoom-wrapper .map-control--change-map-type .map-type-switch {
	width: 28px;
	border: 1px solid #fff;
	padding: 0px;
	border-radius: 2px;
}

/*# sourceMappingURL=../../../../true */